<template>
  <v-dialog v-model="show" max-width="550px" max-height="551px" persistent>
    <v-card class="px-0 pb-0 pt-0">
      <v-card-title class="px-4">
        <span class="text-h5" :style="'color: #182026;font-weight:500;font-size:20px !important'">{{ titulo }}</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon class="ma-0 pa-4">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="ma-0 pa-4">
        <v-form ref="form" v-model="valid" lazy-validation class="pa-0 ma-0">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                id="idCodigo"
                v-model="natureza.codigo"
                label="Código *"
                :rules="[rules.requiredCode, rules.digit, rules.exist]"
                required
                v-mask="'####'"
                maxlength="4"
                minlength="4"
                hide-details="auto"
                :hint="codigoHint"
                persistent-hint
                dense
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pt-4 pb-0">
              <v-text-field
                id="idNome"
                v-model="natureza.nome"
                :rules="[rules.requiredName]"
                label="Nome *"
                dense
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pt-4 pb-0">
              <v-textarea
                rows="4"
                cols="40"
                v-model="natureza.descricao"
                :rules="[rules.requiredDesc]"
                label="Descrição *"
                hide-details="auto"
                dense
                outlined
              >
              </v-textarea>
            </v-col>
            <v-col cols="6" class="pr-2 pt-4 pb-0">
              <v-menu v-model="modalPickerStart" :close-on-content-click="false" :disabled="isHability">
                <v-date-picker
                  v-model="natureza.inicio"
                  no-title
                  @input="modalPickerStart = false"
                  type="month"
                ></v-date-picker>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    id="textPicker"
                    :disabled="isHability"
                    :rules="[rules.requiredStart]"
                    v-model="dateStartFormated"
                    v-on="on"
                    outlined
                    label="Início *"
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    clearable
                    v-mask="'##/####'"
                  >
                  </v-text-field>
                </template>
              </v-menu>
            </v-col>
            <v-col cols="6" class="pl-2 pt-4 pb-0">
              <v-menu v-model="modalPickerEnd" :close-on-content-click="false" :disabled="isHability">
                <v-date-picker
                  v-model="natureza.fim"
                  no-title
                  @input="modalPickerEnd = false"
                  type="month"
                ></v-date-picker>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    id="textPicker"
                    :disabled="isHability"
                    v-model="dateEndFormated"
                    v-on="on"
                    outlined
                    label="Término"
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    clearable
                    v-mask="'##/####'"
                  >
                  </v-text-field>
                </template>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
        <div class="text-left pt-8" style="font-size: 14px; color: #575767; font-weight: 400">
          * Campos de preenchimento obrigatório
        </div>
        <div class="text-red pl-2" style="color: #610006" v-if="invalidForm">Preencher todos os campos obrigatórios</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close" id="btnCancel" class="btn-ag-tertiary"> Cancelar</v-btn>
        <v-btn @click="save()" class="btn-ag-primary" id="btnConfirmOredit" :disabled="isEnableSave">
          {{ labelBotao }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import eSocialService from '@/services/eSocialService.js';
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '../../../../../main.js';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'ModalRubricNatureNew',
  props: ['natures'],

  data() {
    return {
      valid: true,
      codigoHint: 'Preencha 4 números (0-9)',
      natureza: {
        codigo: '',
        nome: '',
        descricao: '',
        inicio: '',
        fim: '',
      },
      invalidForm: false,
      titulo: 'Adicionar natureza',
      labelBotao: 'Salvar',
      show: false,
      persistentCodigo: true,
      dateMessageError: '',
      existentCode: true,
      isHability: false,
      modalPickerStart: false,
      modalPickerEnd: false,
      rules: {
        requiredCode: (code) => !!code || 'Preencha 4 números (0-9)',
        requiredName: (name) => !!name || 'Preencha um nome',
        requiredDesc: (desc) => !!desc || 'Preencha uma descrição',
        requiredStart: (start) => !!start || 'Preencha o início',
        digit: (code) => /^\d{4}$/.test(code) || 'Preencha 4 números (0-9)',
        exist: (code) => !this.codeExist(code) || 'Este código já existe! Preencha outro código',
      },
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    };
  },

  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
    isEnableSave() {
      let count = 0;
      if (![undefined, null].includes(this.natureza.codigo)) {
        if (!this.isEmpty(this.natureza.codigo)) {
          ++count;
        }
      }
      if (![undefined, null].includes(this.natureza.nome)) {
        if (!this.isEmpty(this.natureza.nome)) {
          ++count;
        }
      }

      if (![undefined, null].includes(this.natureza.descricao)) {
        if (!this.isEmpty(this.natureza.descricao)) {
          ++count;
        }
      }
      if (![undefined, null].includes(this.natureza.inicio)) {
        ++count;
      }
      if (this.existentCode) {
        --count;
      }
      if (count === 4) {
        return false;
      } else {
        return true;
      }
    },
    dateStartFormated: {
      get() {
        if (this.natureza.inicio) {
          var dataFormatada = this.formatDate(this.natureza.inicio);
          return dataFormatada;
        } else {
          return null;
        }
      },
      set(data) {
        return data;
      },
    },
    dateEndFormated: {
      get() {
        if (this.natureza.fim) {
          var dataFormatada = this.formatDate(this.natureza.fim);
          return dataFormatada;
        } else {
          return null;
        }
      },
      set(data) {
        return data;
      },
    },
  },

  created() {
    this.init();
  },
  validations: {
    natureza: {
      codigo: { required }, // Matches this.firstName
      nome: { required }, // Matches this.lastName
      inicio: {
        required, // Matches this.contact.email
      },

      descricao: {
        required, // Matches this.contact.email
      },
    },
  },

  methods: {
    checkIsActive(dateEndRubricNature) {
      var partes = dateEndRubricNature.split('-');
      var ano = parseInt(partes[0], 10);
      var mes = parseInt(partes[1], 10);
      var dataValidadeObj = new Date(ano, mes, '1');
      dataValidadeObj.setDate(dataValidadeObj.getDate() - 1);
      var dataAtual = new Date();
      if (dataAtual <= dataValidadeObj) {
        return 'Ativo';
      } else {
        return 'Inativo';
      }
    },
    isEmpty(str) {
      if (str.trim().length === 0) {
        return true;
      } else {
        return false;
      }
    },
    async save() {
      this.$v.natureza.$touch();

      if (this.$v.natureza.$error || this.$v.natureza.$pending) {
        this.$v.$reset();
        this.invalidForm = true;
        return;
      }
      let situation = this.natureza.fim ? this.checkIsActive(this.natureza.fim) : 'Ativo';
      let nature = {
        code: this.natureza.codigo,
        desc: this.natureza.descricao,
        name: this.natureza.nome,
        situation: situation,
        start: this.dateStartFormated,
        end: this.dateEndFormated,
        user: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
      };
      const response = await eSocialService.rubricAutomationConfig.rubricNatureSave(nature);
      if (response) {
        (this.natureza.codigo = ''),
          (this.natureza.nome = ''),
          (this.natureza.descricao = ''),
          (this.natureza.inicio = ''),
          (this.natureza.fim = ''),
          this.$emit('refresh');
        this.close();
        this.showToast('Natureza da rubrica adicionada com sucesso!.', 'success', 78);
      } else {
        this.showToast('Erro ao adicionar natureza da rubrica', 'warning', 78);
      }
    },

    codeExist(code) {
      if (this.natures.some((item) => item.code === code)) {
        this.existentCode = true;
        return true;
      } else {
        this.existentCode = false;
        return false;
      }
    },
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },

    async open() {
      this.natureza = {};
      this.titulo = 'Adicionar natureza';
      this.labelBotao = 'Salvar';
      this.show = true;
    },

    close() {
      this.natureza = {};
      this.invalidForm = false;
      this.$refs.form.resetValidation();
      this.show = false;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.toString().split('-');
      return `${month}/${year}`;
    },
    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
  },
};
</script>
<style scoped lang="scss">

@import './src/design/components/input.scss';
@import './src/design/components/datepicker.scss';

.v-card__actions {
  padding: 16px !important;
}

::v-deep .v-text-field__details .v-messages {
  text-align: right !important;
}
::v-deep .v-text-field__details .v-messages__message {
  background: none !important;
}
::v-deep .v-text-field__details .v-messages.theme--light.error--text {
  text-align: left !important;
  background: url('https://api.iconify.design/bxs/error-circle.svg?color=rgba(150, 45, 51, 1)') no-repeat top left !important;
}
</style>
